


























































































































































import Vue from 'vue';
import {TestInstance} from '~/models';

export default Vue.extend({
  name: 'FeedbackDialog',
  props: {
    value: {type: Boolean, default: false},
    title: {type: String, default: 'Feedback'},
    testInstance: {type: TestInstance},
  },
  data() {
    return {
      form: {
        content: undefined,
        structure: undefined,
        structureText: '',
        didactics: undefined,
        didacticsText: '',
        completeness: undefined,
        completenessText: '',
        comprehensibility: undefined,
        comprehensibilityText: '',
        technicalCorrectness: undefined,
        technicalCorrectnessText: '',
        grammar: undefined,
        grammarText: '',
        difficulty: undefined,
        difficultyText: '',
        advice: undefined,
        adviceText: '',
        good: '',
        notGood: '',
        overall: undefined,
        overallText: '',
        answers: undefined,
        answersText: '',
        availability: undefined,
        availabilityText: '',
        whatElse: '',
        contactMe: '',
      },
    };
  },
  computed: {
    model: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    isValid(): boolean {
      return this.form.content &&
          this.form.structure &&
          this.form.didactics &&
          this.form.completeness &&
          this.form.comprehensibility &&
          this.form.technicalCorrectness &&
          this.form.grammar &&
          this.form.difficulty &&
          this.form.advice &&
          this.form.overall &&
          this.form.answers &&
          this.form.availability;
    },
  },
  methods: {
    async submit() {
      try {
        const feedback = Object.keys(this.form)
            .filter(key => !key.endsWith('Text') || this.form[key.substring(0, key.length - 4)] === 1)
            .map(key => ({
              question: this.$t(`feedback.${key}`),
              answer: this.form[key],
              key,
            }));
        await this.$store.dispatch('feedback/submitTestFeedback', {feedback, testId: this.testInstance?.testId, testInstanceId: this.testInstance?.id});
        this.model = false;
        this.$emit('submit');
      } catch (err) {
        await this.$store.dispatch('snackbar/showError', err);
      }
    },
  },
});
