



























































































































































































































import moment from 'moment';
import Vue from 'vue';
import ImageViewer from '~/components/common/ImageViewer.vue';
import Moment from '~/components/common/Moment.vue';
import FeedbackDialog from '~/components/test/FeedbackDialog.vue';
import {Question, TestInstance} from '~/models';
import {Feedback} from '~/models/Feedback';

export default Vue.extend({
  name: 'TestDetailPage',
  components: {Moment, ImageViewer, FeedbackDialog},
  data() {
    return {
      feedbackConfirmationDialog: false,
      feedbackSubmitted: false,
      finalTestDialog: false,
      feedbackDialog: false,
      dialog: {show: false, question: null},
      img: {show: false, path: null},
      imgDialog: {show: false, figures: null},
      testInstanceResponse: null,
    };
  },
  async created() {
    try {
      this.testInstanceResponse = await this.$store.dispatch('testInstance/fetchById', this.testInstanceId);
      if (this.$store.state.testInstance.finalTestDialog && this.showFeedbackButton) {
        this.finalTestDialog = true;
        this.$store.dispatch('testInstance/showFinalTestDialog', false);
      }
    } catch (err) {
      await this.$store.dispatch('snackbar/showError', err);
    }
  },
  computed: {
    testInstanceId() {
      return Number(this.$route.params.id);
    },
    instance(): TestInstance | null {
      return TestInstance.query().whereId(this.testInstanceId).withAll().with('questions.answers|figures|explanationFigures').first();
    },
    duration(): string {
      const diff = moment(this.instance.finishedAt).diff(this.instance.createdAt);
      return moment.utc(diff).format('HH:mm:ss');
    },
    showFeedbackButton(): boolean {
      return !this.feedbackSubmitted && this.instance.passed && this.instance.feedbackSubmitted === false && [Feedback.Optional, Feedback.Mandatory].includes(this.instance.feedback);
    },
    isFeedbackMandatory(): boolean {
      return this.instance.feedback === Feedback.Mandatory;
    },
  },
  methods: {
    getBreadcrumbs(question: Question) {
      if (!this.testInstanceResponse) {
        return null;
      }
      const responseQuestion = this.testInstanceResponse.questions.find((q: any) => q.id === question.id);
      return responseQuestion.breadCrumbs;
    },
    getCorrectAnswer(question: Question) {
      return question.answers.find(a => a.isCorrect);
    },
    getProvidedAnswer(question: Question) {
      return this.instance.answers.find(a => a.questionId === question.id);
    },
    isCorrect(question: Question): boolean {
      return this.getCorrectAnswer(question).id === this.getProvidedAnswer(question)?.id;
    },
  },
});
